import { UTILS } from '../utils/utils';

const SENDEMAIL_SERVICE = {
  async sendEmail(form) {
    emailjs.init({
      publicKey: process.env.EJS_API_KEY,
    });
    emailjs.sendForm(process.env.EJS_SERVICE_ID, process.env.EJS_TEMPLATE_ID, '#ao__book-a-demo-form')
      .then((response) => {
        form.reset();
        form.querySelector('button[type="submit"]').classList.remove('is-loading');
        document.querySelector('#modal-send-form-message .success-message').classList.remove('hidden');
        document.querySelector('button[data-modal-target="modal-send-form-message"]').click();
        // console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        form.querySelector('button[type="submit"]').classList.remove('is-loading');
        document.querySelector('#modal-send-form-message .fail-message').classList.remove('hidden');
        document.querySelector('button[data-modal-target="modal-send-form-message"]').click();
        // console.log('FAILED...', error);
      });
  }
}

export { SENDEMAIL_SERVICE }