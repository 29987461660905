import Pristine from 'pristinejs/dist/pristine';
import { SENDEMAIL_SERVICE } from '../services/sendEmailService';

const HANDLES = {
    init() {
        this.contactForm();
    },
    contactForm() {
        const form = document.getElementById('ao__book-a-demo-form');
        if (form !== null) {
            const pristine = new Pristine(form);
            form.addEventListener('submit', (e) => {
                e.preventDefault();
                let valid = pristine.validate();
                if (valid) {
                    form.querySelector('button[type="submit"]').classList.add('is-loading');
                    setTimeout((e) => {
                        console.log('ok');
                        return SENDEMAIL_SERVICE.sendEmail(form);
                    }, 1000);
                }
            });
        }
    },
}

export { HANDLES }