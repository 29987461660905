/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'locomotive-scroll/dist/locomotive-scroll.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

/* Import Js Plugins (ES6 Module) and custom JS */
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Accordion, Modal } from 'flowbite';

gsap.registerPlugin(ScrollTrigger);

import '../plugins/cookieconsent-init';

const sm = window.matchMedia('(max-width: 767px)');
const md = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
// const lg = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');

const UTILS = {
    init() {
        this.locomotiveScroll();
        this.initialScreenAnimation();
        this.cardsAnimation();
        this.topNavigationMenu();
        this.copyPrintYear();
    },
    locomotiveScroll() {
        let scrollContainer = document.querySelector('[data-scroll-container]')
        const locomotiveScroll = new LocomotiveScroll({
            el: scrollContainer,
            smooth: true,
            resetNativeScroll: true,
            lerp: 0.05,
            tablet: {
                smooth: true,
                lerp: 0.5
            },
            smartphone: {
                smooth: true,
                lerp: 0.5
            }
        });
        new ResizeObserver(() => locomotiveScroll.update()).observe(
            document.querySelector('[data-scroll-container]')
        );
        locomotiveScroll.on('scroll', (instance) => {
            ScrollTrigger.update();
            document.documentElement.setAttribute('data-scrolling', instance.direction);

            // detect hero element for header interaction
            let header = document.querySelector('.ao__header');
            let heroDetect = document.querySelector('.ao__hero-detect');
            if (heroDetect.getBoundingClientRect().top < 0) {
                header.classList.add('bg-main/70');
            } else {
                header.classList.remove('bg-main/70');
            }
        });
        ScrollTrigger.scrollerProxy('[data-scroll-container]', {
            scrollTop( value ) {
                return arguments.length ? locomotiveScroll.scrollTo( value, 0, 0 ) : locomotiveScroll.scroll.instance.scroll.y;
            },
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
            },
            pinType: document.querySelector( '[data-scroll-container]' ).style.transform ? 'transform' : 'fixed'
        });

        // primary nav scroll navigation
        let primaryNavLinks = document.querySelectorAll('.ao__top-nav ul li span');
        primaryNavLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                let anchor = e.currentTarget.dataset.anchor;
                if (anchor === '#ao__book-a-demo-form') {
                    document.getElementById('book-a-demo-form-firstname').focus();
                }
                if (! document.querySelector('.ao__top-nav').classList.contains('hidden')) {
                    document.getElementById('close-menu-button').click();
                }
                locomotiveScroll.scrollTo(document.querySelector(anchor), {
                    offset: -200
                });
            });
        });

        ScrollTrigger.addEventListener('refresh', () => locomotiveScroll.update());
        ScrollTrigger.refresh();
    },
    initialScreenAnimation() {
      let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.ao__initial-screen',
            pin: true,
            start: '+=0',
            end: '+=1400',
            scrub: true,
            scroller: '[data-scroll-container]',
            // markers: true
          }
      });
      if (sm.matches) {
          tl.addLabel('start initialScreenAnimation')
              .to('.ao__initial-screen figure img', { scale: 28, ease: 'circ.inOut' })
              .to('.ao__initial-screen figure img', { x: 547, ease: 'circ.inOut' }, 0)
              .to('.ao__initial-screen', { opacity: 0, zIndex: 1 })
              .addLabel('end initialScreenAnimation');
      } else if (md.matches) {
          tl.addLabel('start initialScreenAnimation')
              .to('.ao__initial-screen figure img', { scale: 29, ease: 'circ.inOut' })
              .to('.ao__initial-screen figure img', { x: 750, ease: 'circ.inOut' }, 0)
              .to('.ao__initial-screen', { opacity: 0, zIndex: 1 })
              .addLabel('end initialScreenAnimation');
      } else {
          tl.addLabel('start initialScreenAnimation')
              .to('.ao__initial-screen figure img', { scale: 30, ease: 'circ.inOut' })
              .to('.ao__initial-screen figure img', { x: 1030, ease: 'circ.inOut' }, 0)
              .to('.ao__initial-screen', { opacity: 0, zIndex: 1 })
              .addLabel('end initialScreenAnimation');
      }

      let tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: '.ao__hero',
            pin: true,
            start: '+=0',
            end: '+=500',
            scrub: true,
            scroller: '[data-scroll-container]',
            // markers: true
          }
      });
      tl2.addLabel('start initialScreenAnimation2')
        .to('.ao__hero', { opacity: 1, zIndex: 99, ease: 'circ.in' })
        .to('header', { display: 'block' })
        .addLabel('end initialScreenAnimation2');
    },
    cardsAnimation() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.ao__benefits',
                toggleActions: 'restart none reverse none',
                start: 'top center',
                end: '+=280',
                scroller: '[data-scroll-container]',
                // markers: true
            }
        });
        if (sm.matches) {
            tl.addLabel('start cardsAnimation')
                .to('.ao__benefits .cards-wrap .card-1', { margin: 0, rotation: '-3', duration: 0.5, ease: 'circ.in' })
                .to('.ao__benefits .cards-wrap .card-2', { margin: 0, rotation: '3', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-3', { margin: 0, rotation: '-3', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-4', { margin: 0, rotation: '3', duration: 0.5, ease: 'circ.in' }, 0)
                .addLabel('end cardsAnimation');

        } else if (md.matches) {
            tl.addLabel('start cardsAnimation')
                .to('.ao__benefits .cards-wrap .card-1', { margin: 0, rotation: '-3', duration: 0.5, ease: 'circ.in' })
                .to('.ao__benefits .cards-wrap .card-2', { margin: 0, rotation: '3', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-3', { margin: 0, rotation: '-3', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-4', { margin: 0, rotation: '3', duration: 0.5, ease: 'circ.in' }, 0)
                .addLabel('end cardsAnimation');
        } else {
            tl.addLabel('start cardsAnimation')
                .to('.ao__benefits .cards-wrap .card-1', { margin: 0, rotation: '-10', duration: 0.5, ease: 'circ.in' })
                .to('.ao__benefits .cards-wrap .card-2', { margin: 0, rotation: '-1', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-3', { margin: 0, rotation: '10', duration: 0.5, ease: 'circ.in' }, 0)
                .to('.ao__benefits .cards-wrap .card-4', { margin: 0, rotation: '-2', duration: 0.5, ease: 'circ.in' }, 0)
                .addLabel('end cardsAnimation');
        }

        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.ao__benefits',
                start: 'top center',
                end: '140% top',
                scrub: true,
                scroller: '[data-scroll-container]',
                // markers: true
            }
        });
        tl2.addLabel('start cardsAnimation')
            .to('body', { backgroundColor: '#24A7C5', duration: 0.5 })
            .to('body', { backgroundColor: '#F8F8F8', duration: 0.5 })
            .addLabel('end cardsAnimation');
    },
    topNavigationMenu() {
        let toggleMenuButton = document.querySelector('#toggle-menu-button');
        let menu = document.querySelector('#toggle-menu');
        let openMenuButton = document.querySelector('#open-menu-button');
        let closeMenuButton = document.querySelector('#close-menu-button');
        let itemMenu = document.querySelectorAll('#toggle-menu a');

        toggleMenuButton.addEventListener('click', (e) => {
            menu.classList.toggle('hidden');
            openMenuButton.classList.toggle('hidden');
            closeMenuButton.classList.toggle('hidden');
        });
        itemMenu.forEach((el, index) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                toggleMenuButton.click();
            });
        });
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    }
}

export { UTILS }